import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bluemicro-firmware"
    }}>{`BlueMicro Firmware`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`BlueMicro firmware is the recommended firmware for the nice!nano. It's built specifically for wireless boards using the same chip as the nice!nano.`}</p>
    <h2 {...{
      "id": "get-started"
    }}>{`Get Started`}</h2>
    <p>{`Out of the sake of not having outdated docs, I will instead point towards the official BlueMicro firmware docs.`}</p>
    <h3 {...{
      "id": "documentation"
    }}><a parentName="h3" {...{
        "href": "http://bluemicro.jpconstantineau.com/#"
      }}>{`Documentation`}</a></h3>
    <h3 {...{
      "id": "github"
    }}><a parentName="h3" {...{
        "href": "https://github.com/jpconstantineau/BlueMicro_BLE"
      }}>{`GitHub`}</a></h3>
    <h2 {...{
      "id": "notes"
    }}>{`Notes`}</h2>
    <ul>
      <li parentName="ul">{`The BlueMicro firmware supports much more than just the nice!nano, so you must remember to specify the breakoutboard type in the board definition. Details for this will be in the BlueMicro documentation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      